<template>
  <div class="index">
    <div class="header">
      <div class="logo">
        <img src="@/assets/icon/notes.png" alt="logo">
        <a>Notes</a>
      </div>
      <div class="tool">
        <!-- <img src="@/assets/icon/help.png" alt="help"> -->
        <img @click="showSettingMenu = true" src="@/assets/icon/settings.png" alt="settings">
      </div>
    </div>
    <div class="content">
      <div class="folder">
        <van-sidebar class="sidebar" v-model="activeKey">
          <van-sidebar-item class="sidebarItem" :title="item.name" v-for="(item, index) in folder" :key="index" />
        </van-sidebar>
        <div class="add" @click="showAddFolder = true">
          <img src="@/assets/icon/add.png" alt="add">
          <a>新建文件夹</a>
        </div>
      </div>
      <div class="notes">
        <div v-if="folder[activeKey].notes.length > 0">
          <div class="note" :class="{ 'done': item.done }" v-for="(item, index) in folder[activeKey].notes" :key="index"
            @click="setDone(index)">
            <van-swipe-cell>
              <div class="message">
                <div class="title">
                  <a>{{ item.title }}</a>
                  <div class="radio"></div>
                </div>
                <div class="remark">{{ item.remark }}</div>
              </div>
              <template #right>
                <van-button square text="删除" type="danger" class="delete-button" @click="deleteNote(index)" />
              </template>
            </van-swipe-cell>
          </div>
          <div class="addNote" @click="showAddNote = true">
            <img src="@/assets/icon/addNote.png" alt="add">
            <a>添加事项</a>
          </div>
        </div>
        <div class="empty" v-else>
          <img src="@/assets/image/empty.png" alt="empty">
          <p>暂无待办事项</p>
          <a @click="showAddNote = true">点击添加</a>
        </div>
      </div>
    </div>
    <!-- 设置 -->
    <van-action-sheet v-model="showSettingMenu" @select="onSettingSelect" :actions="settingMenu" cancel-text="取消"
      close-on-click-action />
    <!-- 管理文件夹 -->
    <van-popup v-model="showManageFolder" position="bottom" round :style="{ height: '50%' }">
      <div class="manageFolder">
        <div class="title">
          <div class="name">
            <img src="@/assets/icon/folder.png" alt="folder">
            <a>管理文件夹</a>
          </div>
          <img class="close" @click="showManageFolder = false" src="@/assets/icon/close.png" alt="close">
        </div>
        <div class="list">
          <div class="item" v-for="(item, index) in deepCloneFolder" :key="index">
            <span>{{ index + 1 }}.</span>
            <van-field v-model="item.name" maxlength="5" :disabled="index < 2" placeholder="请输入文件夹名称" />
            <a class="delent" @click="deleteFolder(index)">删除</a>
          </div>
        </div>
        <div class="save">
          <a @click="saveFolderEdit">保存</a>
        </div>
      </div>
    </van-popup>
    <!-- 新建文件夹 -->
    <van-dialog v-model="showAddFolder" title="文件夹名称" show-cancel-button confirmButtonColor="#24d1f6"
      @confirm="createFolder">
      <van-field v-model="folderName" placeholder="请输入文件夹名称" size="large" maxlength="5" show-word-limit
        input-align="center" />
    </van-dialog>
    <!-- 添加事项 -->
    <van-dialog v-model="showAddNote" title="待办事项" show-cancel-button confirmButtonColor="#24d1f6"
      @confirm="createNote">
      <van-field v-model="noteTitle" required label="事项名称" maxlength="20" show-word-limit placeholder="请输入事项名称" />
      <van-field name="radio" label="是否完成">
        <template #input>
          <van-radio-group v-model="noteDone" direction="horizontal" icon-size="14px" checked-color="#24d1f6">
            <van-radio :name="true">已完成</van-radio>
            <van-radio :name="false">未完成</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field type="textarea" v-model="noteRemark" rows="4" label="备注" maxlength="50" show-word-limit
        placeholder="请输入备注" />
    </van-dialog>
    <!-- ICP许可证号 -->
    <div class="icp">
      <a href="https://beian.miit.gov.cn">ICP许可证号 粤ICP备2023016796号-3</a>
    </div>
  </div>
</template>

<script>
let defaultFolder = [{
  name: '我的一天',
  notes: []
}, {
  name: '日常待办',
  notes: []
}, {
  name: '工作事项',
  notes: []
}, {
  name: '重要事项',
  notes: []
}]
export default {
  data() {
    return {
      showSettingMenu: false,
      showManageFolder: false,
      settingMenu: [{
        id: 1,
        name: '文件夹管理'
      },
      {
        id: 2,
        name: '清空数据', subname: '清空后无法复原，谨慎操作'
      }],
      activeKey: 0,
      longPressTimeout: null,
      folder: defaultFolder,
      deepCloneFolder: '',
      showAddFolder: false,
      folderName: '',
      showAddNote: false,
      noteTitle: '',
      noteRemark: '',
      noteDone: false
    }
  },
  mounted() {
    let folder = localStorage.getItem('folder')
    if (folder) {
      this.folder = JSON.parse(folder)
    }
  },
  watch: {
    folder: {
      handler(newVal) {
        localStorage.setItem('folder', JSON.stringify(newVal))
      },
      deep: true
    }
  },
  methods: {
    // 选择设置菜单
    onSettingSelect(res) {
      const { id } = res
      switch (id) {
        // 文件夹管理
        case 1:
          this.deepCloneFolder = JSON.parse(JSON.stringify(this.folder))
          this.showManageFolder = true
          break;
        // 清空数据
        case 2:
          this.$dialog.confirm({
            title: '提示',
            message: '是否确认清空文件及待办数据？',
            confirmButtonColor: '#24d1f6',
            showCancelButton: true
          }).then(() => {
            this.folder = defaultFolder
            this.deepCloneFolder = ''
            localStorage.removeItem('folder')
            this.$toast('清空成功');
          }).catch(() => { })
          break;
        default:
          break;
      }
    },
    // 删除文件夹
    deleteFolder(index) {
      if (index < 2) {
        this.$toast('系统文件夹，无法删除');
        return
      }
      this.activeKey = 0
      this.deepCloneFolder.splice(index, 1)
    },
    // 保存文件夹修改
    saveFolderEdit() {
      localStorage.setItem('folder', JSON.stringify(this.deepCloneFolder))
      this.folder = this.deepCloneFolder
      this.showManageFolder = false
    },
    // 创建文件夹
    createFolder() {
      if (!this.folderName) {
        this.$toast('请输入文件夹名称');
        return
      }
      this.folder.push({
        name: this.folderName,
        notes: []
      })
      this.folderName = ''
    },
    // 更新状态
    setDone(index) {
      let isDone = this.folder[this.activeKey].notes[index].done
      this.$dialog.confirm({
        title: '提示',
        message: `是否确认${isDone ? '开启' : '完成'}此项？`,
        confirmButtonColor: '#24d1f6',
        showCancelButton: true
      }).then(() => {
        this.folder[this.activeKey].notes[index].done = !isDone
      }).catch(() => { })
    },
    // 添加事项
    createNote() {
      if (!this.noteTitle) {
        this.$toast('请输入事项名称');
        return
      }
      this.folder[this.activeKey].notes.unshift({
        title: this.noteTitle,
        done: this.noteDone,
        remark: this.noteRemark
      })
      this.noteTitle = ""
      this.noteDone = false
      this.noteRemark = ""
    },
    // 删除事项
    deleteNote(index) {
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认删除此项？',
        confirmButtonColor: '#24d1f6',
        showCancelButton: true
      }).then(() => {
        this.folder[this.activeKey].notes.splice(index, 1)
      }).catch(() => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.index {

  .header {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 60px;
    background-color: #ffffff;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        pointer-events: none;
      }

      a {
        padding-left: 5px;
        color: #333333;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
    }

    .tool {
      img {
        margin-left: 10px;
        width: 26px;
        height: 26px;
      }
    }
  }

  .content {
    display: flex;

    .folder {
      width: 90px;
      height: calc(100vh - 60px);
      background-color: #f7f8fa;
      overflow-y: scroll;

      .sidebar {
        width: 100%;

        .sidebarItem {
          padding: 15px 0;
          line-height: 14px;

          ::v-deep(.van-sidebar-item__text) {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            color: #333333;
            text-align: center;
          }
        }

        ::v-deep(.van-sidebar-item--select) {
          background-color: rgba(36, 209, 246, .05);
        }


        ::v-deep(.van-sidebar-item--select::before) {
          background-color: #24d1f6;
        }

        ::v-deep(.van-sidebar-item--select:active) {
          background-color: rgba(36, 209, 246, .05);
        }

      }

      .add {
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: rgba(248, 132, 72, .05);
        }

        img {
          width: 12px;
          height: 12px;
        }

        a {
          padding-left: 5px;
          font-size: 12px;
          color: #f88448;
        }
      }
    }

    .notes {
      padding: 10px;
      flex: 1;
      height: calc(100vh - 120px);
      overflow-y: scroll;

      .note {
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 10px;
        overflow: hidden;

        .message {
          padding: 15px;

          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
              flex: 1;
              font-size: 14px;
              color: #333333;
            }

            .radio {
              margin-left: 10px;
              width: 10px;
              height: 10px;
              border: 1px solid #24d1f6;
              border-radius: 10px;
            }
          }

          .remark {
            margin-top: 5px;
            font-size: 12px;
            color: #999999;
          }
        }

        .delete-button {
          height: 100%;
        }
      }

      .done {
        .message {
          .title {
            a {
              text-decoration: line-through;
            }

            .radio {
              position: relative;

              &::after {
                position: absolute;
                left: 50%;
                top: 50%;
                content: '';
                transform: translate(-50%, -50%);
                width: 6px;
                height: 6px;
                background-color: #24d1f6;
                border-radius: 6px;
              }
            }
          }

          .remark {
            text-decoration: line-through;
          }
        }
      }

      .empty {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 150px;
          height: 150px;
        }

        p {
          font-size: 14px;
          color: #999999;
        }

        a {
          margin-top: 15px;
          padding: 5px 20px;
          font-size: 14px;
          color: #24d1f6;
          border: 1px solid #24d1f6;
          border-radius: 50px;
        }
      }

      .addNote {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 12px;
          height: 12px;
        }

        a {
          padding-left: 5px;
          font-size: 12px;
          color: #24d1f6;
        }
      }
    }
  }

  .manageFolder {
    position: relative;

    .title {
      position: sticky;
      top: 0;
      z-index: 10;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #ffffff;
      box-shadow: 0 2px 2px rgba(0, 0, 0, .05);

      .name {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        a {
          padding-left: 10px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
        }
      }

      .close {
        width: 15px;
        height: 15px;
      }
    }

    .list {
      padding: 0 15px;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 12px;
          color: #999999;
        }

        .delent {
          width: 50px;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
          color: #f88448;
          text-align: center;
          border-radius: 5px;
        }
      }
    }

    .save {
      position: sticky;
      bottom: 0;
      z-index: 10;
      padding: 15px;
      display: flex;
      justify-content: center;
      background-color: #ffffff;

      a {
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        border-radius: 10px;
        background-color: #24d1f6;
      }
    }
  }

  .addFolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .icp {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    width: calc(100% - 90px);
    height: 40px;
    line-height: 40px;
    text-align: center;

    a {
      font-size: 12px;
      color: #999999;
    }
  }
}
</style>